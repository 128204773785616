// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TermsAndConditions_termsConditionsContainer__1OEr6 {
  color: white;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.TermsAndConditions_title__BOB5F {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.TermsAndConditions_sectionTitle__\\+AKRZ {
  font-size: 1.5rem;
  margin-top: 2rem;
}

.TermsAndConditions_paragraph__sG6Ru {
  margin-bottom: 1rem;
}

.TermsAndConditions_contactInfo__1Z3i3 {
  margin-top: 1.5rem;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/pages/terms_and_condition/TermsAndConditions.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,qBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;AACJ","sourcesContent":[".termsConditionsContainer {\n    color: white;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n}\n\n.title {\n    font-size: 2rem;\n    margin-bottom: 1.5rem;\n}\n\n.sectionTitle {\n    font-size: 1.5rem;\n    margin-top: 2rem;\n}\n\n.paragraph {\n    margin-bottom: 1rem;\n}\n\n.contactInfo {\n    margin-top: 1.5rem;\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"termsConditionsContainer": `TermsAndConditions_termsConditionsContainer__1OEr6`,
	"title": `TermsAndConditions_title__BOB5F`,
	"sectionTitle": `TermsAndConditions_sectionTitle__+AKRZ`,
	"paragraph": `TermsAndConditions_paragraph__sG6Ru`,
	"contactInfo": `TermsAndConditions_contactInfo__1Z3i3`
};
export default ___CSS_LOADER_EXPORT___;
