// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: rgb(59 130 246);
  --secondary-color: #004b70;
  --primary-text-color: #ffffff;
  --secondary-text-color: #868686;
  background-color: #0e131f;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}

.spacer {
  flex: 1 1;
}

.spacer-center {
  flex: 1 1;
  text-align: center;
}

.spacer-right {
  flex: 1 1;
  text-align: end;
}

a {
  text-decoration: none;
}
a:link {
  color: var(--primary-text-color);
}
a:visited {
  color: var(--primary-text-color);
}
a:hover {
  color: var(--primary-color);
}
a.active {
  color: var(--primary-color);
}

.button:hover {
  cursor: pointer;
}

.App {
  background-color: #0e131f;
  min-height: 100vh;
  width: 100vw;
}

.-z-10 {
  z-index: -10;
}

.loading {
  shape-rendering: auto;
  display: block;
  background: transparent;
  margin: auto;
  height: 100vh;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACC,gCAAA;EACA,0BAAA;EACA,6BAAA;EACA,+BAAA;EACA,yBAAA;AACD;;AACA;EACC,UAAA;EACA,SAAA;EACA,sBAAA;EACA,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AAED;AADC;EACC,aAAA;AAGF;;AACA;EACC,SAAA;AAED;;AAAA;EACC,SAAA;EACA,kBAAA;AAGD;;AAAA;EACC,SAAA;EACA,eAAA;AAGD;;AAAA;EACC,qBAAA;AAGD;AAFC;EACC,gCAAA;AAIF;AAFC;EACC,gCAAA;AAIF;AAFC;EACC,2BAAA;AAIF;AADC;EACC,2BAAA;AAGF;;AAEC;EACC,eAAA;AACF;;AAGA;EACC,yBAAA;EACA,iBAAA;EACA,YAAA;AAAD;;AAGA;EACC,YAAA;AAAD;;AAGA;EACC,qBAAA;EACA,cAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,kCAAA;AAAD;;AAGA;EACC;IACC,uBAAA;EAAA;EAGD;IACC,yBAAA;EADA;AACF","sourcesContent":[":root {\n\t--primary-color: rgb(59 130 246);\n\t--secondary-color: #004b70;\n\t--primary-text-color: #ffffff;\n\t--secondary-text-color: #868686;\n\tbackground-color: #0e131f;\n}\n* {\n\tpadding: 0;\n\tmargin: 0;\n\tbox-sizing: border-box;\n\t-ms-overflow-style: none; /* IE and Edge */\n\tscrollbar-width: none; /* Firefox */\n\t&::-webkit-scrollbar {\n\t\tdisplay: none;\n\t}\n}\n\n.spacer {\n\tflex: 1 1 0px;\n}\n.spacer-center {\n\tflex: 1 1 0px;\n\ttext-align: center;\n}\n\n.spacer-right {\n\tflex: 1 1 0px;\n\ttext-align: end;\n}\n\na {\n\ttext-decoration: none;\n\t&:link {\n\t\tcolor: var(--primary-text-color);\n\t}\n\t&:visited {\n\t\tcolor: var(--primary-text-color);\n\t}\n\t&:hover {\n\t\tcolor: var(--primary-color);\n\t}\n\n\t&.active {\n\t\tcolor: var(--primary-color);\n\t}\n}\n\n.button {\n\t&:hover {\n\t\tcursor: pointer;\n\t}\n}\n\n.App {\n\tbackground-color: #0e131f;\n\tmin-height: 100vh;\n\twidth: 100vw;\n}\n\n.-z-10 {\n\tz-index: -10;\n}\n\n.loading {\n\tshape-rendering: auto;\n\tdisplay: block;\n\tbackground: transparent;\n\tmargin: auto;\n\theight: 100vh;\n\tanimation: spin 1s infinite linear;\n}\n\n@keyframes spin {\n\t0% {\n\t\ttransform: rotate(0deg);\n\t}\n\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
