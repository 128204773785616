const Practical = () => (
	<svg
		className="h-5 w-5 flex-shrink-0 fill-gray-500 transition duration-75 group-hover:fill-gray-900 dark:fill-gray-400 dark:group-hover:fill-white"
		viewBox="0 0 1024 1024"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M981.333333 469.333333a21.333333 21.333333 0 0 0-21.333333 21.333334v85.333333a85.333333 85.333333 0 0 1-85.333333 85.333333H149.333333a85.333333 85.333333 0 0 1-85.333333-85.333333V149.333333a85.333333 85.333333 0 0 1 85.333333-85.333333h725.333334a85.333333 85.333333 0 0 1 85.333333 85.333333 21.333333 21.333333 0 0 0 42.666667 0 128 128 0 0 0-128-128H149.333333a128 128 0 0 0-128 128v426.666667a128 128 0 0 0 128 128h725.333334a128 128 0 0 0 128-128v-85.333333a21.333333 21.333333 0 0 0-21.333334-21.333334z" />
		<path d="M981.333333 298.666667a21.333333 21.333333 0 0 0-21.333333 21.333333v85.333333a21.333333 21.333333 0 0 0 42.666667 0v-85.333333a21.333333 21.333333 0 0 0-21.333334-21.333333zM966.186667 219.52a21.333333 21.333333 0 0 0 0 30.293333 21.333333 21.333333 0 0 0 30.293333 0l2.56-3.413333a11.946667 11.946667 0 0 0 1.92-3.626667 13.226667 13.226667 0 0 0 1.706667-3.84 26.88 26.88 0 0 0 0-4.266666 21.333333 21.333333 0 0 0-36.48-15.146667zM896 960H128a21.333333 21.333333 0 0 0 0 42.666667h768a21.333333 21.333333 0 0 0 0-42.666667zM682.666667 853.333333a21.333333 21.333333 0 0 0 0-42.666666H341.333333a21.333333 21.333333 0 0 0 0 42.666666zM256 576a21.333333 21.333333 0 0 0 21.333333 21.333333h258.773334a21.333333 21.333333 0 0 0 13.226666-4.693333l134.4-106.666667 1.92-1.92 1.493334-1.493333a21.333333 21.333333 0 0 0 2.773333-4.693333l109.866667-277.333334A21.333333 21.333333 0 0 0 789.333333 170.666667l-158.506666-64a21.333333 21.333333 0 0 0-27.733334 11.946666l-109.866666 277.333334A21.333333 21.333333 0 0 0 490.666667 405.333333v3.413334L512 554.666667H277.333333a21.333333 21.333333 0 0 0-21.333333 21.333333z m282.24-136.96l90.24 35.84-76.586667 61.013333zM633.813333 155.733333l119.04 47.146667-94.293333 238.08-118.826667-47.146667z" />
	</svg>
);

export default Practical;
