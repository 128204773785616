export const semesters = [
	'Semester 1',
	'Semester 2',
	'Semester 3',
	'Semester 4',
	'Semester 5',
	'Semester 6',
	'Semester 7',
	'Semester 8',
];

export const units = ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 4'];

export const NotesTags = ['Handwritten', 'Printed', 'Self Made', 'By Teacher'];

export const PaperTags = ['Solved', 'Unsolved', 'Mid-Term', 'End-Term'];

export const PracticleFileTags = [
	'HandWritten',
	'Printed',
	'Computer Science',
	'Electrical',
	'Information Technology',
	'Mechanical',
	'Civil',
];

export const BooksTags = [
	'Recommended by Teachers',
	'Easy to understand',
	'Reference Book',
	'Indian Author',
	'Foreign Author',
];

export const ExperienceLevels = [
	'Intership',
	'Entry Level',
	'Associate',
	'Mid-Senior Level',
	'Senior Level',
	'Director',
	'Executive',
];

export const JobTypes = ['Full Time', 'Part Time', 'Contract', 'Freelance', 'Internship', 'Other'];

export const JobsTags = ['C/C++', 'JTML', 'Java', 'Javascript', 'Flutter'];

export const AlertsTags = ['Jobs', 'Internship', 'Learning'];
