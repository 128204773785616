// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy_privacyPolicyContainer__iOTgS {
  color: white;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.PrivacyPolicy_title__0KP9a {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.PrivacyPolicy_sectionTitle__r8aJR {
  font-size: 1.5rem;
  margin-top: 2rem;
}

.PrivacyPolicy_paragraph__bWRc3 {
  margin-bottom: 1rem;
}

.PrivacyPolicy_link__UuuWZ {
  color: #03a9f4;
  text-decoration: none;
}

.PrivacyPolicy_link__UuuWZ:hover {
  text-decoration: underline;
}

.PrivacyPolicy_contactInfo__gB\\+NF {
  margin-top: 1.5rem;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/pages/privacy_policy/PrivacyPolicy.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,qBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,cAAA;EACA,qBAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;AACJ","sourcesContent":[".privacyPolicyContainer {\n    color: white;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n}\n\n.title {\n    font-size: 2rem;\n    margin-bottom: 1.5rem;\n}\n\n.sectionTitle {\n    font-size: 1.5rem;\n    margin-top: 2rem;\n}\n\n.paragraph {\n    margin-bottom: 1rem;\n}\n\n.link {\n    color: #03a9f4;\n    text-decoration: none;\n}\n\n.link:hover {\n    text-decoration: underline;\n}\n\n.contactInfo {\n    margin-top: 1.5rem;\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacyPolicyContainer": `PrivacyPolicy_privacyPolicyContainer__iOTgS`,
	"title": `PrivacyPolicy_title__0KP9a`,
	"sectionTitle": `PrivacyPolicy_sectionTitle__r8aJR`,
	"paragraph": `PrivacyPolicy_paragraph__bWRc3`,
	"link": `PrivacyPolicy_link__UuuWZ`,
	"contactInfo": `PrivacyPolicy_contactInfo__gB+NF`
};
export default ___CSS_LOADER_EXPORT___;
