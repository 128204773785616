// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel_ele__nfTEW {
  animation: 1s Carousel_fadeIn__Jfaui;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes Carousel_fadeIn__Jfaui {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/carousel/Carousel.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,6BAAA;EACA,kBAAA;AACD;;AAEA;EACC;IACC,UAAA;EACA;EACD;IACC,mBAAA;IACA,UAAA;EACA;AACF","sourcesContent":[".ele {\n\tanimation: 1s fadeIn;\n\tanimation-fill-mode: forwards;\n\tvisibility: hidden;\n}\n\n@keyframes fadeIn {\n\t0% {\n\t\topacity: 0;\n\t}\n\t100% {\n\t\tvisibility: visible;\n\t\topacity: 1;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ele": `Carousel_ele__nfTEW`,
	"fadeIn": `Carousel_fadeIn__Jfaui`
};
export default ___CSS_LOADER_EXPORT___;
